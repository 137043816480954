.nav-bar {
    display: flex;
    flex-direction: row; 
    padding: 30px;
    align-items: flex-end;
    justify-content: space-between;

}

.nav-logo {
    width: 250px;
    height: auto;
}

@media only screen and (max-width: 768px) {
    .nav-logo {
        width: 100px; /* Decrease the width of the logo for mobile devices */
    }
}
  
.nav-text {
    font-size: 16px;
    text-align: right; 
    font-family: 'Afacad', sans-serif;
}

.nav-text ul {
    list-style-type: none;
    align-items: flex-end;
    margin-bottom: -2px;
}

/* Media query for tablets */
@media (min-width: 768px) and (max-width: 1024px) {
    .nav-text {
        font-size: 20px; /* Font size for tablets */
    }
}

/* Media query for desktops and laptops */
@media (min-width: 1025px) {
    .nav-text {
        font-size: 24px; /* Font size for desktops and laptops */
    }
}

.image-container {
    position: relative;
}

.image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity here (0.5 means 50% opacity) */
}
  
.banner {
    width: 100%;
    height: auto;
}
  
.headline {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: white;
    font-size: 6em;
    font-family: 'Afacad', sans-serif;
    font-weight: bold;
}

.headline-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: white;
    font-size: 2em;
    font-family: 'Afacad', sans-serif;
}

/* Media query for screens between 768px and 1075px wide */
@media only screen and (min-width: 768px) and (max-width: 1075px) {
    .headline {
        font-size: 4em; /* Decrease font size to keep the words on the same line */
    }
}

@media only screen and (max-width: 768px) {
    .headline {
        font-size: 2em;
    }

    .headline-block {
        padding-top: 5px;
        font-size: 1em; 
        top: 60%;
    }
}

.headline-block ul {
    list-style-type: none;
    padding: 0;
}

.separator {
    border: none;
    height: 4px; /* Adjust thickness as needed */
    background-color: white; /* Change color as needed */
    margin: 20px auto; /* Adjust spacing as needed */
    position: absolute;
    top: 28%; /* Adjust position to be below headline */
    left: 50%;
    transform: translateX(-50%);
    width: 50%; /* Adjust width as needed */
    max-width: 200px; /* Adjust max width as needed */
}

@media only screen and (max-width: 768px) {
    .separator {
        display: none; /* Hide the separator in the mobile view */
    }
}

/* Media query for screens between 945px and 768px wide */
@media only screen and (min-width: 768px) and (max-width: 945px) {
    .separator {
        top: 22%%; /* Adjust the position to be in the middle */
    }
}

.body-bio {
    display: flex;
    flex-direction: row; 
    padding: 30px;
    align-items: center;
}

.headshot {
    width: 80%;
    height: auto;
    padding-right: 30px;
}

@media only screen and (max-width: 768px) {
    .headshot {
        width: 80%; /* Adjust width for mobile devices */
        margin-right: 0; /* Remove any right margin */
        padding-right: 0; /* Remove any right padding */
        margin-bottom: 20px; /* Add some margin to separate the image from the text */
    }
}

.bio-paragraph {
    font-size: 1.5em;
    text-align: left; 
    font-family: 'Afacad', sans-serif;
}

@media only screen and (max-width: 768px) {
    .body-bio {
        flex-direction: column; /* Change flex-direction to column */
        align-items: center; /* Center items vertically */
    }

    .headshot {
        order: -1; /* Move the headshotImage to the top */
        margin-bottom: 20px; /* Add some margin between the headshot and the bio */
    }

    .bio-paragraph {
        text-align: center; /* Center-align the text */
        font-size: 1.2em;
    }
}

.body-will {
    background-color: black;
    color: white;
    padding: 30px;
}

.question {
    color: white;
    font-size: 2em;
    font-weight: bold;
    font-family: 'Afacad', sans-serif;
}

.question-text {
    font-family: 'Afacad', sans-serif;
    font-size: 1.5em;
    padding: 20px 0px 0px 0px;
}

@media only screen and (max-width: 768px) {
    .question-text {
        font-size: 1.2em;
    }
    
}

.question-text ul { 
    list-style-type: none;
    margin-left: -40px;
}

.question-white {
    color: black;
    font-size: 2em;
    font-weight: bold;
    font-family: 'Afacad', sans-serif;
}

.question-text-white {
    padding-right: 30px;
    padding: 20px 0px 0px 0px;
    font-size: 1.5em;
}

@media only screen and (max-width: 768px) {
    .question-text-white {
        font-size: 1.2em;
    }
    
}

.body-landlord {
    display: flex;
    flex-direction: row;
    padding: 30px;
    align-items: center;
    font-family: 'Afacad', sans-serif;
}

.question-map-container {
    flex: 0 0 70%; /* Take 70% of the available space */
}

.map-container {
    flex: 0 0 30%; /* Take 30% of the available space */
}

@media only screen and (max-width: 768px) {
    .body-landlord {
        flex-direction: column; /* Change flex-direction to column */
        align-items: center; /* Center items vertically */
    }

    .map-container {
        order: -1;
        width: 100%; /* Ensure the map fills its container */
        margin-right: 0; /* Remove any right margin */
        padding-left: 30px;
        padding-right: 0; /* Remove any right padding */
        margin-bottom: 20px; /* Add some margin to separate the map from other elements */
    }

    .question-map-container {
        text-align: center; /* Center-align the text */
    }
}

.map-wrapper {
    padding-right: 30px; /* Add space to the right side of the map */
}

.map-container iframe {
    width: 100%; /* Make sure the map fills its container */
    height: 100%;
}

.footer {
    background-color: black;
    color: white;
    font-family: 'Afacad', sans-serif;
    padding: 20px 0px;
}

.footertext-address {
    padding: 0; /* Remove padding */
    line-height: .5; /* Set line-height to 1 to remove space between lines */
    font-weight: 100;
    font-size: 16px;
}

.footertext-white {
    padding: 0; /* Remove padding */
    line-height: .5; /* Set line-height to 1 to remove space between lines */
}

.footertext-legal {
    margin-top: 0; /* Remove top margin */
    line-height: 1; /* Set line-height to 1 to remove space between lines */
}